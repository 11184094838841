import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import AppDevelopment from '../component/appDevelopmentComponent';
import FooterComponent from '../component/footerComponent';
import HomeComponent from '../component/homeComponent';
import Layout from '../component/layout';
import ProjectComponent from '../component/projectsComponent';
import ReviewComponent from '../component/reviewComponent';
import SolutionsHomeComponent from '../component/solutionsHomeComponent';
import HomeImage from '../images/home_banner.png';
const Home = props => {
  const {
    data: {
      wpPage: {
        seo,
        homePageFullCycleSection: {
          homePageFullCycleAppDescription,
          homePageFullCycleAppTitle,
          homePageFullCycleBusinessDescription,
          homePageFullCycleBusinessTitle,
          homePageFullCycleDesignDescription,
          homePageFullCycleDesignTitle,
          homePageFullCycleDevelopmentDescription,
          homePageFullCycleDevelopmentTitle,
          homePageFullCycleProductDescription,
          homePageFullCycleProductTitle,
          homePageFullCyclePublishingDescription,
          homePageFullCyclePublishingTitle,
          homePageFullCycleQualityDescription,
          homePageFullCycleQualityTitle,
          homePageFullCycleTitle,
        },
        homePageHeaderSection: {
          homeHeaderBookButton,
          homeHeaderDescription,
          homeHeaderLearnButton,
          homeHeaderTitle,
        },
        homePageProjectsSection: {
          homePageProjectsAgreedDescription,
          homePageProjectsAgreedChallenge,
          homePageProjectsAgreedTitle,
          homePageProjectsBashDescription,
          homePageProjectsBashChallenge,
          homePageProjectsBashTitle,
          homePageProjectsHyraDescription,
          homePageProjectsHyraChallenge,
          homePageProjectsHyraTitle,
          homePageProjectsNaxxaDescription,
          homePageProjectsNaxxaChallenge,
          homePageProjectsNaxxaTile,
          homePageProjectsTitle,
          homePageProjectsWedaxDescription,
          homePageProjectsWedaxChallenge,
          homePageProjectsWedaxTitle,
          homePageProjectAgreedImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homePageProjectAgreedImage },
            },
          },
          homePageProjectPurposeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homePageProjectPurposeImage },
            },
          },
          homePageProjectsHyraImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homePageProjectsHyraImage },
            },
          },
          homePageProjectsNaxxaImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homePageProjectsNaxxaImage },
            },
          },
          homePageProjectsWedaxImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homePageProjectsWedaxImage },
            },
          },
        },
        homePageReviewSection: {
          homePageReviewAgreedClient,
          homePageReviewAgreedDescription,
          homePageReviewAgreedPosition,
          homePageReviewBashClient,
          homePageReviewBashDescription,
          homePageReviewBashPosition,
          homePageReviewTitle,
          homePageReviewHyraClient,
          homePageReviewHyraDescription,
        },
        homePageServicesSection: {
          homePageServicesDescription,
          homePageServicesMobileDescription,
          homePageServicesMobileSalesforceDescription,
          homePageServicesMobileTitle,
          homePageServicesMobileSalesforceTitle,
          homePageServicesProductDescription,
          homePageServicesProductTitle,
          homePageServicesTitle,
          homePageServicesWebDescription,
          homePageServicesWebTitle,
        },
      },
    },
    pageContext: { title },
  } = props;
  const homeLearnMoreRef = useRef(null);
  const homeOnLearnMoreClick = () => {
    console.log(homeLearnMoreRef.current.scrollTo());
    if (homeLearnMoreRef && homeLearnMoreRef.current) {
      homeLearnMoreRef.current.scrollIntoView();
    }
  };
  return (
    <Layout title={title} seo={seo}>
      <HomeComponent
        homeTitle={homeHeaderTitle}
        homeDescription={homeHeaderDescription}
        homeButtonBook={homeHeaderBookButton}
        homeButtonLearn={homeHeaderLearnButton}
        backgroundImage={HomeImage}
        isHomePage={true}
        homeOnLearnMoreClick={homeOnLearnMoreClick}
      />
      <SolutionsHomeComponent
        isHome='true'
        solutionsTitle={homePageServicesTitle}
        solutionsDescription={homePageServicesDescription}
        solutionsProductTitle={homePageServicesProductTitle}
        solutionsProductDescription={homePageServicesProductDescription}
        solutionsLink={homeHeaderLearnButton}
        solutionsWebApplicaationTitle={homePageServicesWebTitle}
        solutionsWebApplicaationDescription={homePageServicesWebDescription}
        solutionsMobileApplicationtTitle={homePageServicesMobileTitle}
        solutionsMobileApplicationtDescription={
          homePageServicesMobileDescription
        }
        solutionsSalesforceTitle={homePageServicesMobileSalesforceTitle}
        solutionsSalesforceDescription={
          homePageServicesMobileSalesforceDescription
        }
        solutionsDescriptionLink={homeHeaderLearnButton}
        homeLearnMoreRef={homeLearnMoreRef}
      />
      <AppDevelopment
        isHome={true}
        developmentTitleButton='Our services'
        developmentTitle={homePageFullCycleTitle}
        developmentQualityTitle={homePageFullCycleQualityTitle}
        developmentQualityDescription={homePageFullCycleQualityDescription}
        developmentDemoTitle={homePageFullCycleProductTitle}
        developmentDemoDescription={homePageFullCycleProductDescription}
        developmentPublishingTitle={homePageFullCyclePublishingTitle}
        developmentPublishingDescription={
          homePageFullCyclePublishingDescription
        }
        developmentAppTitle={homePageFullCycleAppTitle}
        developmentAppDescription={homePageFullCycleAppDescription}
        developmentBusinessTitle={homePageFullCycleBusinessTitle}
        developmentBusinessDescription={homePageFullCycleBusinessDescription}
        developmentDesignTitle={homePageFullCycleDesignTitle}
        developmentDesignDescription={homePageFullCycleDesignDescription}
        developmentSwiperTitle={homePageFullCycleDevelopmentTitle}
        developmentSwiperDescription={homePageFullCycleDevelopmentDescription}
      />
      <ProjectComponent
        projectTitle={homePageProjectsTitle}
        projectAgreedImage={homePageProjectAgreedImage}
        projectAgreedTitle={homePageProjectsAgreedTitle}
        projectAgreedDescription={homePageProjectsAgreedDescription}
        projectBashImage={homePageProjectPurposeImage}
        projectBashTitle={homePageProjectsBashTitle}
        projectBashDescription={homePageProjectsBashDescription}
        projectNaxxaImage={homePageProjectsNaxxaImage}
        projectNaxxaTitle={homePageProjectsNaxxaTile}
        projectNaxxaDescription={homePageProjectsNaxxaDescription}
        projectWedaxImage={homePageProjectsWedaxImage}
        projectWedaxTitle={homePageProjectsWedaxTitle}
        projectWedaxDescription={homePageProjectsWedaxDescription}
        projectHyraImage={homePageProjectsHyraImage}
        projectHyraTitle={homePageProjectsHyraTitle}
        projectHyraDescription={homePageProjectsHyraDescription}
        projectWedaxSubdescription={homePageProjectsWedaxChallenge}
        projectHyraSubdescription={homePageProjectsHyraChallenge}
        projectBashSubdescription={homePageProjectsBashChallenge}
        projectNaxxaSubdescription={homePageProjectsNaxxaChallenge}
        projectAgreedSubdescription={homePageProjectsAgreedChallenge}
      />
      <ReviewComponent
        reviewTitle={homePageReviewTitle}
        reviewText={homePageReviewAgreedDescription}
        reviewName={homePageReviewAgreedClient}
        reviewPosition={homePageReviewAgreedPosition}
        reviewBashText={homePageReviewBashDescription}
        reviewBashName={homePageReviewBashClient}
        reviewBashPosition={homePageReviewBashPosition}
        reviewHyraText={homePageReviewHyraDescription}
        reviewHyraName={homePageReviewHyraClient}
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      seo {
        metaDesc
        title
      }
      homePageFullCycleSection {
        homePageFullCycleAppDescription
        homePageFullCycleAppTitle
        homePageFullCycleBusinessDescription
        homePageFullCycleBusinessTitle
        homePageFullCycleDesignDescription
        homePageFullCycleDesignTitle
        homePageFullCycleDevelopmentDescription
        homePageFullCycleDevelopmentTitle
        homePageFullCycleProductDescription
        homePageFullCycleProductTitle
        homePageFullCyclePublishingDescription
        homePageFullCyclePublishingTitle
        homePageFullCycleQualityDescription
        homePageFullCycleQualityTitle
        homePageFullCycleTitle
      }
      homePageHeaderSection {
        homeHeaderBookButton
        homeHeaderDescription
        homeHeaderLearnButton
        homeHeaderTitle
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageProjectsSection {
        homePageProjectsNaxxaChallenge
        homePageProjectsWedaxChallenge
        homePageProjectsHyraChallenge
        homePageProjectsBashChallenge
        homePageProjectsAgreedChallenge
        homePageProjectsAgreedDescription
        homePageProjectsAgreedTitle
        homePageProjectsBashDescription
        homePageProjectsBashTitle
        homePageProjectsHyraDescription
        homePageProjectsHyraTitle
        homePageProjectsNaxxaDescription
        homePageProjectsNaxxaTile
        homePageProjectsTitle
        homePageProjectsWedaxDescription
        homePageProjectsWedaxTitle
        homePageProjectAgreedImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageProjectPurposeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageProjectsHyraImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageProjectsNaxxaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageProjectsWedaxImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageReviewSection {
        homePageReviewAgreedClient
        homePageReviewAgreedDescription
        homePageReviewAgreedPosition
        homePageReviewBashClient
        homePageReviewBashDescription
        homePageReviewBashPosition
        homePageReviewHyraClient
        homePageReviewHyraDescription
        homePageReviewTitle
      }
      homePageServicesSection {
        homePageServicesDescription
        homePageServicesMobileDescription
        homePageServicesMobileSalesforceDescription
        homePageServicesMobileTitle
        homePageServicesMobileSalesforceTitle
        homePageServicesProductDescription
        homePageServicesProductTitle
        homePageServicesTitle
        homePageServicesWebDescription
        homePageServicesWebTitle
      }
    }
  }
`;
export default Home;
