import React from "react";
import TitleIcone from '../../static/assets/Features/TitleIcon.svg'
import {  Pagination,Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
SwiperCore.use([Autoplay]);
const ProjectComponent = (props) =>{
    const {
        projectTitle,
        projectAgreedImage,
        projectAgreedTitle,
        projectAgreedDescription,
        projectBashImage,
        projectBashTitle,
        projectBashDescription,
        projectNaxxaImage,
        projectNaxxaTitle,
        projectNaxxaDescription,
        projectWedaxImage,
        projectWedaxTitle,
        projectWedaxDescription,
        projectHyraImage,
        projectHyraTitle,
        projectHyraDescription,
        projectAgreedSubtitle,
        projectAgreedSubdescription,
        projectBashSubdescription,
        projectNaxxaSubdescription,
        projectHyraSubdescription,
        projectWedaxSubdescription
    } = props;
    return(
        <div className="projects-section">
        <div className="full-container">
        <div className="home-section-textIcone">
        <TitleIcone className="home-section-titleIcone"/>
            <h4 className="projects-section-title">{projectTitle}</h4>
            </div>
            <Swiper 
                pagination={{
                    type: "progressbar",
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  spaceBetween={50}
                  loop
                  slidesPerView={1}
                  
                >
                    <SwiperSlide>
                        <Link className="projects-section-wrapper" to="/agreed">
                     <div className="row">
                         <div className="col-md-6 projects-section-colImage">
                             <GatsbyImage image={projectAgreedImage} className="projects-section-image" alt="agreedProject" />
                         </div>
                         <div className="col-md-6 projects-section-colText">
                             <p className="projects-section-subtitle">{projectAgreedTitle}</p>
                             <p className="projects-section-description">{projectAgreedDescription}</p>
                             <p className="projects-section-agreedSubtitle">{projectAgreedSubtitle}</p>
                             <p className="projects-section-description" dangerouslySetInnerHTML={{ __html: projectAgreedSubdescription }}></p>
                         </div>
                     </div>
                     </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <Link to="/purposolver" className="projects-section-wrapper">
                     <div className="row">
                         <div className="col-md-6 projects-section-colImage">
                             <GatsbyImage alt="projectBash" image={projectBashImage} className="projects-section-image"/>
                         </div>
                         <div className="col-md-6 projects-section-colText">
                             <p className="projects-section-subtitle">{projectBashTitle}</p>
                             <p className="projects-section-description">{projectBashDescription}</p>
                             <p className="projects-section-description" dangerouslySetInnerHTML={{ __html: projectBashSubdescription }}></p>
                         </div>
                     </div>
                     </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <Link to="/naxxa"  className="projects-section-wrapper">
                     <div className="row">
                         <div className="col-md-6 projects-section-colImage">
                             <GatsbyImage alt="naxxaProject" image={projectNaxxaImage} className="projects-section-image"/>
                         </div>
                         <div className="col-md-6 projects-section-colText">
                             <p className="projects-section-subtitle">{projectNaxxaTitle}</p>
                             <p className="projects-section-description">{projectNaxxaDescription}</p>
                             <p className="projects-section-description" dangerouslySetInnerHTML={{ __html: projectNaxxaSubdescription }}></p>
                         </div>
                     </div>
                     </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <Link to="/oneclickapp" className="projects-section-wrapper">
                     <div className="row">
                         <div className="col-md-6 projects-section-colImage">
                             <GatsbyImage alt="wedaxProject" image={projectWedaxImage} className="projects-section-image"/>
                         </div>
                         <div className="col-md-6 projects-section-colText">
                             <p className="projects-section-subtitle">{projectWedaxTitle}</p>
                             <p className="projects-section-description">{projectWedaxDescription}</p>
                             <p className="projects-section-description" dangerouslySetInnerHTML={{ __html: projectWedaxSubdescription }}></p>
                         </div>
                     </div>
                     </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <Link to="/hyra" className="projects-section-wrapper">
                     <div className="row">
                         <div className="col-md-6 projects-section-colImage">
                             <GatsbyImage alt="hyraProject" image={projectHyraImage} className="projects-section-image"/>
                         </div>
                         <div className="col-md-6 projects-section-colText">
                             <p className="projects-section-subtitle">{projectHyraTitle}</p>
                             <p className="projects-section-description">{projectHyraDescription}</p>
                             <p className="projects-section-description" dangerouslySetInnerHTML={{ __html: projectHyraSubdescription }}></p>
                         </div>
                     </div>
                     </Link>
                    </SwiperSlide>
                </Swiper>
        </div>
        </div>
    )
}
export default ProjectComponent;