import React from 'react';
import TitleIcone from '../../static/assets/Features/TitleIcon.svg';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import JhonIcon from '../../static/assets/review/ryan.svg';
import MaryIcon from '../../static/assets/review/stiven.svg';
import HyraIcon from '../../static/assets/review/hyra.svg';
const ReviewComponent = props => {
  const {
    reviewTitle,
    reviewText,
    reviewName,
    reviewPosition,
    reviewBashText,
    reviewBashName,
    reviewBashPosition,
    reviewHyraText,
    reviewHyraName,
    reviewHyrPosition,
  } = props;
  return (
    <div className='review-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <h5 className='review-section-title'>{reviewTitle}</h5>
        </div>
        <Swiper
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mySwiper'
          spaceBetween={50}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
          }}
        >
          <SwiperSlide>
            <div className='review-section-wrapper'>
              <p className='review-section-text'>
                {reviewText?.replace('Motomtech', 'UpTech sh.p.k')}
              </p>
              <div className='review-section-icone'>
                <JhonIcon />
                <div className='review-section-information'>
                  <p className='review-section-information-name'>
                    {reviewName}
                  </p>
                  <p className='review-section-information-position'>
                    {reviewPosition}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-section-wrapper'>
              <p className='review-section-text'>
                {reviewBashText?.replaceAll('Motomtech', 'UpTech sh.p.k')}
              </p>
              <div className='review-section-icone'>
                <MaryIcon />
                <div className='review-section-information'>
                  <p className='review-section-information-name'>
                    {reviewBashName}
                  </p>
                  <p className='review-section-information-position'>
                    {reviewBashPosition}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-section-wrapper'>
              <p className='review-section-text'>
                {reviewHyraText?.replace('Motomtech', 'UpTech sh.p.k')}
              </p>
              <div className='review-section-icone'>
                <HyraIcon />
                <div className='review-section-information'>
                  <p className='review-section-information-name'>
                    {reviewHyraName}
                  </p>
                  <p className='review-section-information-position'>
                    {reviewHyrPosition}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
export default ReviewComponent;
