import React from 'react';
import HeaderComponent from './headerComponent';
import { Link } from 'gatsby';
const HomeComponent = props => {
  const {
    homeTitle,
    homeDescription,
    homeButtonBook,
    homeButtonLearn,
    isHomePage,
    homeOnLearnMoreClick,
  } = props;
  return (
    <div
      className='home'
      style={{ backgroundImage: `url(${props.backgroundImage})` }}
    >
      <div className='home-section'>
        <HeaderComponent />
        <div className='full-container'>
          <div>
            <div className='home-section-wrapper'>
              <div className='home-section-text'>
                <h1 className='home-section-text-title'>{homeTitle}</h1>
                <p className='home-section-text-description'>
                  {homeDescription}
                </p>
              </div>
              {isHomePage && (
                <div className='home-section-button'>
                  <Link to='/contact' className='home-section-button-book'>
                    {homeButtonBook}
                  </Link>
                  <button
                    className='home-section-button-learn'
                    onClick={homeOnLearnMoreClick}
                  >
                    {homeButtonLearn}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeComponent;
